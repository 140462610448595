import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
import Props from 'components/Props';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=List",
        "componentName": "List"
      }}>{`<List>
  <ListItem>Do the dishes</ListItem>
  <ListItem>Hang the laundy</ListItem>
  <ListItem>Cuddle with the cats</ListItem>
</List>
`}</code></pre>
    <h2 {...{
      "id": "props",
      "style": {
        "position": "relative"
      }
    }}>{`Props`}</h2>
    <h4 {...{
      "id": "list",
      "style": {
        "position": "relative"
      }
    }}>{`List`}</h4>
    <Props identifier="List" mdxType="Props" />
    <h4 {...{
      "id": "listitem",
      "style": {
        "position": "relative"
      }
    }}>{`ListItem`}</h4>
    <Props identifier="ListItem" mdxType="Props" />
    <h2 {...{
      "id": "as",
      "style": {
        "position": "relative"
      }
    }}>{`As`}</h2>
    <p><inlineCode parentName="p">{`default: ul`}</inlineCode></p>
    <p>{`Lists come in both unordered and ordered variants. Changing the `}<inlineCode parentName="p">{`as`}</inlineCode>{` prop will
switch functionality.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<List as="ul">
  <ListItem>Do the dishes</ListItem>
  <ListItem>Hang the laundy</ListItem>
  <ListItem>Cuddle with the cats</ListItem>
</List>
<List as="ol">
  <ListItem>Do the dishes</ListItem>
  <ListItem>Hang the laundy</ListItem>
  <ListItem>Cuddle with the cats</ListItem>
</List>
`}</code></pre>
    <h2 {...{
      "id": "icon",
      "style": {
        "position": "relative"
      }
    }}>{`Icon`}</h2>
    <p><inlineCode parentName="p">{`default: Bullet`}</inlineCode></p>
    <p>{`Icons of a ListItem can be variated throughout a single list.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<List as="ul">
  <ListItem icon={Checkmark}>Do the dishes</ListItem>
  <ListItem icon={Close}> Hang the laundy</ListItem>
  <ListItem icon={Close}>Cuddle with the cats</ListItem>
</List>
`}</code></pre>
    <h2 {...{
      "id": "iconcolor",
      "style": {
        "position": "relative"
      }
    }}>{`IconColor`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`To further emphasize an icon's meaning, you can convey further information by
changing its color.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<List as="ul">
  <ListItem icon={Checkmark} iconColor="greenBase">
    Do the dishes
  </ListItem>
  <ListItem iconColor="primaryBase">Hang the laundy</ListItem>
  <ListItem icon={Close} iconColor="redBase">
    Cuddle with the cats
  </ListItem>
</List>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      